import React, { FC, useState, useRef, useEffect, ReactNode } from "react";

import { AnimatePresence } from "framer-motion";

import Flag from "components/atoms/Flag";
import WithInspector from "components/organisms/WithInspector";

import { type InformationSource } from "api/report/report-types";

import S from "./styles";

export enum BasicDetailsType {
  text = "text",
  country = "country",
  address = "address",
  stock = "stock"
}

interface StockItem {
  name: string;
  sources: InformationSource[];
  ticker?: string;
  startDate?: {
    day: number;
    month: number;
    monthShort: string;
    year: number;
  };
  endDate?: {
    day: number;
    month: number;
    monthShort: string;
    year: number;
  };
}

interface BasicDetailsStock {
  title: string;
  itemType: BasicDetailsType.stock;
  items: StockItem[];
}

interface CountryItem {
  code: string;
  name: string;
  sources: InformationSource[];
}

interface AddressItem {
  values: string[];
  sources: InformationSource[];
}

interface TextItem {
  value: string;
  label?: string;
  sources: InformationSource[];
}

interface BasicDetailsText {
  title: string;
  itemType: BasicDetailsType.text;
  items: TextItem[];
}

interface BasicDetailsCountry {
  title: string;
  itemType: BasicDetailsType.country;
  items: CountryItem[];
}

interface BasicDetailsAddress {
  title: string;
  itemType: BasicDetailsType.address;
  items: AddressItem[];
}

export type BasicDetail =
  | BasicDetailsText
  | BasicDetailsCountry
  | BasicDetailsAddress
  | BasicDetailsStock;

const DEFAULT_NUMBER_OF_ITEMS_TO_SHOW = 3;

const DetailsItem: FC<BasicDetail> = ({ items, itemType, title }) => {
  const [showAll, setShowAll] = useState(false);

  const getNumberOfItemsToShow = () => {
    switch (itemType) {
      case BasicDetailsType.text:
        return DEFAULT_NUMBER_OF_ITEMS_TO_SHOW;
      case BasicDetailsType.stock:
      case BasicDetailsType.address:
      case BasicDetailsType.country:
      default:
        return 1;
    }
  };

  const numberOfItemsToShow = getNumberOfItemsToShow();

  const itemsToShow = showAll ? items : items.slice(0, numberOfItemsToShow);

  const renderItems = () => {
    if (items.length === 0)
      return <S.DetailsItemValue>Not identified</S.DetailsItemValue>;

    switch (itemType) {
      case BasicDetailsType.country: {
        return (itemsToShow as CountryItem[]).map(({ code, name, sources }) => (
          <S.DetailsItemFlagValue
            key={`DetailsItemValue-${name}`}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
          >
            <Flag size="sm" code={code} showCodeString={false} />
            <WithInspector sources={sources}>{name}</WithInspector>
          </S.DetailsItemFlagValue>
        ));
      }
      case BasicDetailsType.address: {
        return (itemsToShow as AddressItem[]).map(({ values, sources }) => (
          <S.DetailsItemLargeValue
            key={`DetailsItemValue-${items.join("")}`}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
          >
            <WithInspector sources={sources}>
              {values.map(value => (
                <div key={`DetailsItemValueAddress-${value}`}>{value}</div>
              ))}
            </WithInspector>
          </S.DetailsItemLargeValue>
        ));
      }
      case BasicDetailsType.stock: {
        return (itemsToShow as StockItem[]).map(
          ({ name, ticker, startDate, endDate, sources }) => (
            <S.DetailsItemLargeValue
              key={`DetailsItemValue-${name}`}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
            >
              <WithInspector sources={sources} topSectionElement={name}>
                <div>{name}</div>
                {ticker && (
                  <S.DetailsItemTagValue>
                    <strong>{ticker}</strong>
                  </S.DetailsItemTagValue>
                )}

                {startDate && (
                  <div>
                    {startDate.monthShort} {startDate.year} -{" "}
                    {endDate
                      ? `${endDate.monthShort} ${endDate.year}`
                      : "Present"}
                  </div>
                )}
              </WithInspector>
            </S.DetailsItemLargeValue>
          )
        );
      }
      default: {
        return (itemsToShow as TextItem[]).map(({ value, label, sources }) => (
          <S.DetailsItemValue
            key={`DetailsItemValue-${value}`}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
          >
            <WithInspector sources={sources} topSectionElement={label || value}>
              {value}
            </WithInspector>
          </S.DetailsItemValue>
        ));
      }
    }
  };

  const onToggleItemsDisplay = () => setShowAll(prev => !prev);

  return (
    <S.DetailsItem>
      <S.DetailsItemHeading level={5}>{title}</S.DetailsItemHeading>
      <S.DetailsItemValues>
        <AnimatePresence initial={false}>{renderItems()}</AnimatePresence>
      </S.DetailsItemValues>
      {items.length > numberOfItemsToShow && (
        <S.ToggleItemsButton onClick={onToggleItemsDisplay}>
          {showAll ? "Show fewer" : `Show all ${items.length}`}
        </S.ToggleItemsButton>
      )}
    </S.DetailsItem>
  );
};

interface Props {
  title: string;
  details: BasicDetail[][];
}

const DetailsSection = ({ children }: { children: ReactNode }) => {
  const [detailsContainerHeight, setDetailsContainerHeight] = useState<
    undefined | number
  >(undefined);
  const detailsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (detailsContainerRef?.current && !detailsContainerHeight) {
      setDetailsContainerHeight(detailsContainerRef.current.offsetHeight);
    }
  }, [detailsContainerRef, detailsContainerHeight]);

  return (
    <S.DetailsSection ref={detailsContainerRef} height={detailsContainerHeight}>
      {children}
    </S.DetailsSection>
  );
};

const BasicDetails: FC<Props> = ({ title, details }) => {
  return (
    <S.Container>
      <S.Heading level={5}>{title}</S.Heading>

      <S.DetailsContainer>
        {details.map((detailItems, index) => (
          <DetailsSection key={`DetailsSection-${detailItems.length}-${index}`}>
            {detailItems.map(detail => (
              <DetailsItem key={`DetailsItem-${detail.title}`} {...detail} />
            ))}
          </DetailsSection>
        ))}
      </S.DetailsContainer>
    </S.Container>
  );
};

export default BasicDetails;

import { styled } from "@linaria/react";

import { fontSize, family } from "styles/fonts";
import { grey, standardColors } from "styles/colors";

const EdgeLabelContainer = styled.div<{ labelX: number; labelY: number }>`
  transform: ${({ labelX, labelY }) =>
    `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`};
  position: absolute;
  background-color: ${standardColors.white};
  padding: 5px;
  text-align: center;
  max-width: 112px;
`;

const EdgeLabel = styled.div`
  font-size: ${fontSize.xs};
  font-family: ${family.interSemiBold};
`;

const EdgeSubLabel = styled.div`
  font-size: ${fontSize["2xs"]};
  color: ${grey.mid};
`;

const S = { EdgeLabelContainer, EdgeLabel, EdgeSubLabel };

export default S;

import { styled } from "@linaria/react";

import { purple, standardColors } from "styles/colors";

import {
  family,
  fontSize,
  headingLetterSpacing,
  lineHeight
} from "styles/fonts";

import { ReactComponent as RootSVG } from "img/icons/rootIcon.svg";

const RootIcon = styled(RootSVG)`
  height: 48px;
  width: 48px;
  color: ${purple.light};
`;

const Label = styled.div`
  color: ${standardColors.black};
  text-align: center;
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  line-height: ${lineHeight.md};
`;

const SecondaryLabel = styled.div`
  display: flex;
  height: 24px;
  padding: 6px 12px;
  align-items: center;
  border-radius: 4px;
  background: ${purple.darker};
  color: ${standardColors.white};
  font-size: ${fontSize.xs};
  font-family: ${family.interSemiBold};
  letter-spacing: ${headingLetterSpacing["2xs"]};
`;

const S = {
  RootIcon,
  Label,
  SecondaryLabel
};

export default S;

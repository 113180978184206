import { styled } from "@linaria/react";
import { standardColors, purple } from "styles/colors";
import { family, fontSize, lineHeight } from "styles/fonts";
import { ReactComponent as PersonSVG } from "img/icons/PersonIcon.svg";
import { ReactComponent as OrgSVG } from "img/icons/OrgIcon.svg";

const PersonIcon = styled(PersonSVG)`
  height: 48px;
  width: 48px;
  color: ${standardColors.black};
`;

const OrganisationIcon = styled(OrgSVG)`
  height: 48px;
  width: 48px;
  color: ${standardColors.black};
`;

const Label = styled.div`
  color: ${standardColors.black};
  text-align: center;
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  line-height: ${lineHeight.md};
  display: -webkit-box;
  max-height: 40px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubLabel = styled.div`
  color: ${purple.darker};
  text-align: center;
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  line-height: ${lineHeight.md};
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const S = {
  Label,
  SubLabel,
  OrganisationIcon,
  PersonIcon,
  LabelContainer
};

export default S;

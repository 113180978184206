import React, { type FC, type ReactNode } from "react";
import SectionFooter from "components/atoms/SectionFooter";

import S from "./styles";

interface Props {
  provider: string;
}

interface ProviderFooterConfig {
  text: string;
  logo: ReactNode;
}

const providers: Record<string, ProviderFooterConfig> = {
  sayari: {
    text: `Xapien's beneficial ownership structure data is supplied by Sayari.
We trace more than 10% shareholding through up to five layers of ownership.`,
    logo: <S.SayariLogo />
  },
  bvd: {
    text: "Xapien's Beneficial ownership structure data is supplied by Moody’s",
    logo: <S.MoodysLogo />
  }
};

const UBOSectionFooter: FC<Props> = ({ provider }) => {
  const config = providers[provider];

  if (!config) return <SectionFooter />;

  return (
    <SectionFooter>
      <span>{config.text}</span>
      {config.logo}
    </SectionFooter>
  );
};

export default UBOSectionFooter;

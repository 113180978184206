export enum UboEntityType {
  person = "Person",
  company = "Company",
  root = "Root",
  edgeLabel = "EdgeLabel"
}

export enum UboNodeType {
  entity = "UboEntityNode",
  label = "UboLabelNode"
}

export interface UboReportShares {
  asOfDate: string;
  percentage?: UboSharePercentage;
  numShares?: number;
  monetaryValue?: {
    value: number;
    currency: string;
  };
}

interface UboResponseNode {
  $type: UboNodeType.entity;
  id: string;
  name: string;
  entityType: UboEntityType.person | UboEntityType.company | UboEntityType.root;
  addresses: string[];
  countries: {
    iso2: string;
    name: string;
  }[];
  identifiers: {
    value: string;
    label: string;
  }[];
  sources: {
    provider: string;
    url: string;
    recordId: string;
  }[];
  integratedPercentage: string | null;
  stateOwned: boolean;
}

interface UboResponseEdge {
  $type: UboNodeType.label;
  id: string;
  name: string;
  edgeType: string;
  fromNodeId: string;
  toNodeId: string;
  shares: UboReportShares;
  isSelfLoop: boolean;
  isDirectOwnership: boolean;
}

export interface UboResponse {
  provider: string;
  maxDepth: number;
  nodes: UboResponseNode[];
  edges: UboResponseEdge[];
}

export interface UboReportIdentifier {
  value: string;
  label: string;
}

export interface UboReportCountry {
  iso2: string;
  name: string;
}

export interface UboSharePercentage {
  upper?: number;
  lower: number;
}

export interface UboReportSource {
  provider: string;
  url: string;
  recordId: string;
}

export interface UboReportNode {
  id: string;
  type: UboEntityType;
  width: number;
  height: number;
  position: { x: number; y: number };
  data: {
    label: string;
    type: UboEntityType;
    addresses: string[];
    countries: UboReportCountry[];
    identifiers: UboReportIdentifier[];
    sources: UboReportSource[];
    integratedPercentage?: string;
    stateOwned?: boolean;
  };
}

export interface UboReportEdge {
  id: string;
  source: string;
  target: string;
  isDirectOwnership: boolean;
  label: string;
  data: {
    sublabel?: string;
  };
}

export interface UboReport {
  nodes: UboReportNode[];
  edges: UboReportEdge[];
  provider: string;
}

import React, { FC } from "react";
import {
  getSmoothStepPath,
  EdgeLabelRenderer,
  BaseEdge,
  type EdgeProps
} from "reactflow";

import S from "./styles";

const UBOEdge: FC<EdgeProps & { data?: { sublabel?: string } }> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label,
  style,
  data
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={style} />
      <EdgeLabelRenderer>
        <S.EdgeLabelContainer labelX={labelX} labelY={labelY} style={{}}>
          <S.EdgeLabel>{label}</S.EdgeLabel>
          {data?.sublabel && <S.EdgeSubLabel>{data.sublabel}</S.EdgeSubLabel>}
        </S.EdgeLabelContainer>
      </EdgeLabelRenderer>
    </>
  );
};

export default UBOEdge;

import { styled } from "@linaria/react";
import { ReactComponent as SayariSVG } from "img/sayari-logo.svg";
import { ReactComponent as MoodysSVG } from "img/moodys-logo.svg";

import { grey } from "styles/colors";

const SayariLogo = styled(SayariSVG)`
  color: ${grey.mid};
  max-height: 100%;
`;

const MoodysLogo = styled(MoodysSVG)`
  color: ${grey.mid};
  max-height: 100%;
`;

const S = { SayariLogo, MoodysLogo };

export default S;

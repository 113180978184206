import React, { FC, useCallback, useState, useEffect } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow,
  ReactFlowProvider
} from "reactflow";
import { useLocation } from "react-router-dom";

import "reactflow/dist/style.css";

import { useEnquiryId } from "util/hooks/useEnquiryId";
import UboReportsApi from "api/ubo-reports";
import type { UboReportNode, UboReportEdge } from "api/ubo-reports";
import { DataNode, RootNode } from "components/molecules/UBONode";
import UBOEdge from "components/molecules/UBOEdge";
import UBOSectionFooter from "components/molecules/UBOSectionFooter";

import ErrorScreen from "components/molecules/ErrorScreen";
import { getLayoutedElements } from "./utils";
import LoadingScreen from "./LoadingScreen";
import S from "./styles";

const nodeTypes = {
  Person: DataNode,
  default: DataNode,
  Company: DataNode,
  Root: RootNode
};

enum UboStatus {
  loading = "loading",
  error = "error",
  success = "success"
}

const ERROR_TEXT =
  // eslint-disable-next-line max-len
  "We are unable to display the Upstream ownership preview (UBO) data for this report at the moment. This could be due to limited or inaccessible data. For further assistance, please reach out to the Customer Success team.";

const LayoutFlow: FC = () => {
  const [uboStatus, setUboStatus] = useState<UboStatus>(UboStatus.loading);
  const [uboProvider, setUboProvider] = useState<string | undefined>(undefined);

  const { fitView } = useReactFlow();
  const enquiryId = useEnquiryId();
  const { search } = useLocation();
  const [uboNodes, setUboNodes, onUboNodesChange] = useNodesState([]);
  const [uboEdges, setUboEdges, onUboEdgesChange] = useEdgesState([]);

  const onLayout = useCallback(
    (nodes: UboReportNode[], edges: UboReportEdge[]) => {
      if (nodes.length === 0 || edges.length === 0) {
        return;
      }

      getLayoutedElements(nodes, edges).then(elements => {
        if (!elements) return;

        setUboNodes([...(elements.nodes as UboReportNode[])]);
        setUboEdges([...(elements.edges as UboReportEdge[])]);

        window.requestAnimationFrame(() => {
          fitView();
        });
      });
    },
    [setUboNodes, setUboEdges, fitView]
  );

  useEffect(() => {
    if (uboNodes.length && uboEdges.length) return;

    const api = new UboReportsApi();

    const params = new URLSearchParams(search);
    const shareToken = params.get("token");

    api
      .getReport({ enquiryId, shareToken })
      .then(({ response, status, message }) => {
        if (status && response) {
          setUboStatus(UboStatus.success);
          setUboProvider(response.provider);
          onLayout(response.nodes, response.edges);
        } else {
          console.error(message);
          setUboStatus(UboStatus.error);
        }
      })
      .catch(({ message }) => {
        console.error(message);
        setUboStatus(UboStatus.error);
      });
  }, [
    enquiryId,
    setUboNodes,
    setUboEdges,
    uboNodes,
    uboEdges,
    onLayout,
    search
  ]);

  if (uboStatus === UboStatus.loading) {
    return <LoadingScreen />;
  }

  if (uboStatus === UboStatus.error) {
    return <ErrorScreen errorText={ERROR_TEXT} showContactButton />;
  }

  if (!uboNodes.length || !uboEdges.length) {
    return <ErrorScreen errorText={ERROR_TEXT} showContactButton />;
  }

  return (
    <>
      <S.Container>
        <ReactFlow
          nodes={uboNodes}
          edges={uboEdges}
          edgeTypes={{ default: UBOEdge }}
          onNodesChange={onUboNodesChange}
          nodeTypes={nodeTypes}
          onEdgesChange={onUboEdgesChange}
          minZoom={0.1}
          proOptions={{ hideAttribution: true }}
          fitView
          fitViewOptions={{
            padding: 0
          }}
        >
          <Controls showInteractive={false} />
        </ReactFlow>
      </S.Container>
      {uboProvider ? <UBOSectionFooter provider={uboProvider} /> : null}
    </>
  );
};

const UBODiagram: FC = () => {
  return (
    <ReactFlowProvider>
      <LayoutFlow />
    </ReactFlowProvider>
  );
};

export default UBODiagram;

import { createContext } from "react";

import {
  type UpcDetailsState,
  type UpcDetailsAction,
  UpcDetailsActions
} from "./types";

export const UpcDetailsContext = createContext({
  state: {} as UpcDetailsState,
  updateState: (_state: UpcDetailsState) => {}
});

export const upcDetailsReducer = (
  state: UpcDetailsState,
  action: UpcDetailsAction
) => {
  switch (action.type) {
    case UpcDetailsActions.updateState: {
      return action.state;
    }
    default: {
      return state;
    }
  }
};

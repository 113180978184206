import React, { FC, MouseEventHandler, useState } from "react";
import ReactTooltip, { TooltipVariant } from "components/atoms/ReactTooltip";
import useViewerMode from "util/hooks/useViewerMode";
import { isPDX } from "static-config";
import { AnimatePresence } from "framer-motion";

import S from "./styles";

export enum SectionType {
  Section = "section",
  Subsection = "subsection",
  Element = "element"
}

interface Props {
  sectionType: SectionType;
  isCopied: boolean;
  onMoveSectionUp?: MouseEventHandler<HTMLButtonElement>;
  onMoveSectionDown?: MouseEventHandler<HTMLButtonElement>;
  onMoveToAnotherSection?: MouseEventHandler<HTMLButtonElement>;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  onCopy?: MouseEventHandler<HTMLButtonElement>;
  onProvideFeedback?: MouseEventHandler<HTMLButtonElement>;
  onRemove?: MouseEventHandler<HTMLButtonElement>;
  onClickOveride?: (title: string) => void;
}

const ActionToolBar: FC<Props> = ({
  sectionType,
  isCopied,
  onMoveSectionUp,
  onMoveSectionDown,
  onMoveToAnotherSection,
  onEdit,
  onCopy,
  onProvideFeedback,
  onRemove,
  onClickOveride
}) => {
  const { isViewerModeEnabled } = useViewerMode();

  const [showToolBar, setShowToolBar] = useState(false);

  const disableToolTips = isPDX;

  const setWarningModalTitle = (title: string) => {
    if (!isPDX) return;
    if (onClickOveride) {
      onClickOveride(title);
    }
  };

  const onToolBarClick = (
    functionToCapture: Function | undefined,
    titleToSet: string
  ) => {
    if (isPDX) {
      setWarningModalTitle(titleToSet);
      return;
    }
    if (functionToCapture) {
      functionToCapture();
    }
  };

  return (
    <AnimatePresence>
      <S.ToolBarWrapper>
        {showToolBar ? (
          <S.ToolBarContainer
            initial={{ x: "50%", scale: 0 }}
            animate={{ x: "0%", scale: 1 }}
            transition={{ type: "easeInOut", duration: 0.2 }}
            onMouseLeave={() => setTimeout(() => setShowToolBar(false), 333)}
          >
            <ReactTooltip
              variant={TooltipVariant.DARK}
              tooltip="Move section up"
              arrow={false}
            >
              <S.ToolbarButton
                aria-label="Move section up button"
                disableToolTips={disableToolTips}
                disabled={
                  sectionType !== SectionType.Subsection ||
                  isViewerModeEnabled ||
                  !onMoveSectionUp
                }
                onClick={() =>
                  onToolBarClick(onMoveSectionUp, "Moving sections")
                }
              >
                <S.UpArrow />
              </S.ToolbarButton>
            </ReactTooltip>

            <ReactTooltip
              variant={TooltipVariant.DARK}
              tooltip="Move section down"
              arrow={false}
            >
              <S.ToolbarButton
                aria-label="Move section down button"
                disableToolTips={disableToolTips}
                disabled={
                  sectionType !== SectionType.Subsection ||
                  isViewerModeEnabled ||
                  !onMoveSectionDown
                }
                onClick={() =>
                  onToolBarClick(onMoveSectionDown, "Moving sections")
                }
              >
                <S.DownArrow />
              </S.ToolbarButton>
            </ReactTooltip>

            <ReactTooltip
              variant={TooltipVariant.DARK}
              tooltip="Move to another section"
              arrow={false}
            >
              <S.ToolbarButton
                aria-label="Move to another section button"
                disableToolTips={disableToolTips}
                disabled={
                  sectionType !== SectionType.Subsection || isViewerModeEnabled
                }
                onClick={() =>
                  onToolBarClick(
                    onMoveToAnotherSection,
                    "Moving to another section"
                  )
                }
              >
                <S.MoveArrow />
              </S.ToolbarButton>
            </ReactTooltip>

            <ReactTooltip
              variant={TooltipVariant.DARK}
              tooltip="Edit title"
              arrow={false}
            >
              <S.ToolbarButton
                aria-label="Edit title button"
                disableToolTips={disableToolTips}
                disabled={isViewerModeEnabled}
                onClick={() => onToolBarClick(onEdit, "Editing titles")}
              >
                <S.Edit />
              </S.ToolbarButton>
            </ReactTooltip>

            <ReactTooltip
              variant={TooltipVariant.DARK}
              tooltip={isCopied ? "Copied!" : "Copy"}
              arrow={false}
            >
              <S.ToolbarButton
                aria-label="Copy Toolbar Selection Button"
                onClick={onCopy}
              >
                <S.Copy />
              </S.ToolbarButton>
            </ReactTooltip>

            <ReactTooltip
              variant={TooltipVariant.DARK}
              tooltip="Feedback"
              arrow={false}
            >
              <S.ToolbarButton
                aria-label="Provide Feedback Selection Toolbar Button"
                onClick={onProvideFeedback}
              >
                <S.Message />
              </S.ToolbarButton>
            </ReactTooltip>

            <ReactTooltip
              variant={TooltipVariant.DARK}
              tooltip="Delete"
              layoutPosition="absolute"
              position="top-end"
              arrow={false}
            >
              <S.DiscardToolbarButton
                aria-label="Delete Selection Toolbar Button"
                disableToolTips={disableToolTips}
                disabled={isViewerModeEnabled}
                onClick={() => onToolBarClick(onRemove, "Deleting sections")}
              >
                <S.Trash disabled={isPDX} />
              </S.DiscardToolbarButton>
            </ReactTooltip>
          </S.ToolBarContainer>
        ) : (
          <S.IconContainer
            aria-label="Open Toolbar Button"
            onClick={() => setShowToolBar(true)}
          >
            <S.ThreeDots />
          </S.IconContainer>
        )}
      </S.ToolBarWrapper>
    </AnimatePresence>
  );
};

export default ActionToolBar;

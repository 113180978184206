import { styled } from "@linaria/react";
import { grey, standardColors } from "styles/colors";

const Container = styled.div`
  width: 100%;
  height: 75vh;
  background: ${standardColors.white};

  .react-flow__edge-path,
  .react-flow__edge.selected .react-flow__edge-path {
    stroke: ${grey.dark};
  }
`;

const S = { Container };

export default S;

import dayjs from "dayjs";
import { FetchResult } from "api/types";
import { apm } from "@elastic/apm-rum";
import { getHeaders } from "services/http";
import config from "config";

import type { UboReport, UboResponse, UboSharePercentage } from "./types";

export { UboEntityType } from "./types";

export type {
  UboReportCountry,
  UboReportSource,
  UboReportShares,
  UboReportIdentifier,
  UboReport,
  UboReportNode,
  UboReportEdge
} from "./types";

type ErrorResponseWithBody = {
  body: {
    error: string;
  };
};

const hasErrorCode = (response: unknown): response is ErrorResponseWithBody =>
  response !== null &&
  typeof response === "object" &&
  "body" in response &&
  response.body !== null &&
  typeof response.body === "object" &&
  "error" in response.body &&
  typeof response.body.error === "string";

const getHeight = (nodeType: string): number => {
  if (nodeType === "EdgeLabel") {
    return 60;
  }

  return 158;
};

const getWidth = (nodeType: string): number => {
  if (nodeType === "EdgeLabel") {
    return 89;
  }

  return 124;
};

const getSharePercentageText = (percentage: UboSharePercentage | undefined) => {
  const lower = percentage?.lower ?? -1;
  const upper = percentage?.upper ?? -1;

  if (lower < 0 && upper < 0) return "";
  if (lower < 0) return `${upper}%`;
  if (upper < 0) return `${lower}%`;
  if (lower === upper) return `${lower}%`;
  return `${lower}-${upper}%`;
};

const getEdgeLabelByNodeType = (
  nodeType: string,
  isDirectOwnership: boolean
): string => {
  const labels: Record<string, string> = {
    has_shareholder: "Shareholding",
    has_beneficial_owner: "Beneficial Owner",
    has_owner: "Owner",
    subsidiary_of: "Parent",
    branch_of: "Parent",
    possibly_same_as: "Possibly Same As",
    has_partner: "Partner"
  };

  const directLabel = isDirectOwnership ? "Direct" : "Indirect";

  if (nodeType in labels) {
    return `${directLabel} ${labels[nodeType]}`;
  }

  return nodeType;
};

export default class UboReports {
  async getReport({
    enquiryId,
    shareToken
  }: {
    enquiryId: string;
    shareToken?: string | null;
  }): Promise<FetchResult<UboReport>> {
    try {
      const { portalApiUrl } = config;
      let endpoint = `${portalApiUrl}/reports/${enquiryId}/ubo-data`;
      if (shareToken) {
        endpoint += `/${shareToken}`;
      }

      const response = await fetch(endpoint, {
        headers: await getHeaders(!shareToken)
      });

      const data = (await response.json()) as UboResponse;

      const nodes = data.nodes.map(
        ({
          id,
          entityType,
          name,
          addresses,
          countries,
          identifiers,
          sources,
          integratedPercentage,
          stateOwned
        }) => ({
          id,
          draggable: false,
          type: entityType,
          width: getWidth(entityType),
          height: getHeight(entityType),
          position: { x: 0, y: 0 },
          data: {
            label: name,
            type: entityType ?? "root",
            countries: countries ?? [],
            identifiers: identifiers ?? [],
            addresses: addresses ?? [],
            sources: sources ?? [],
            integratedPercentage: integratedPercentage ?? undefined,
            stateOwned
          }
        })
      );

      const edges = data.edges
        .filter(edge => !edge.isSelfLoop)
        .map(
          ({
            id,
            edgeType,
            fromNodeId,
            toNodeId,
            isDirectOwnership,
            shares
          }) => {
            const label =
              (edgeType === "has_shareholder"
                ? `${getSharePercentageText(shares?.percentage)} `
                : "") + getEdgeLabelByNodeType(edgeType, isDirectOwnership);

            return {
              id,
              label,
              data: {
                sublabel:
                  shares?.asOfDate &&
                  `As of ${dayjs(shares?.asOfDate, "DD/MM/YYYY").format(
                    "D MMM YYYY"
                  )}`
              },
              source: fromNodeId,
              isDirectOwnership,
              target: toNodeId
            };
          }
        );

      const provider = data.provider?.toLowerCase() ?? "sayari";

      return { status: true, response: { nodes, edges, provider } };
    } catch (e: unknown) {
      apm.captureError(e as Error);

      console.error(e);

      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: "Failed to fetch UBO data" };
    }
  }
}

import ELK, { type ElkExtendedEdge } from "elkjs/lib/elk.bundled";

import type { UboReportNode, UboReportEdge } from "api/ubo-reports";
import { grey } from "styles/colors";

const elk = new ELK({
  defaultLayoutOptions: {
    "elk.algorithm": "layered",
    "elk.direction": "UP",
    "elk.layered.spacing.nodeNodeBetweenLayers": "150",
    "elk.edgeRouting": "SPLINES",
    "elk.layered.allowSelfLoops": "false",
    "elk.layered.mergeEdges": "true",
    "elk.spacing.nodeNode": "150",
    "elk.spacing.nodeEdge": "150",
    "elk.spacing.edgeNode": "200"
  }
});

export const getLayoutedElements = (
  nodes: UboReportNode[],
  edges: UboReportEdge[]
) => {
  const graph = {
    id: "root",
    children: nodes.map(node => ({
      ...node
    })),
    edges: edges.map(edge => ({
      ...edge,
      sources: [edge.source],
      targets: [edge.target]
    }))
  };

  return elk
    .layout(graph)
    .then(layoutedGraph => ({
      nodes:
        layoutedGraph.children?.map(node => ({
          ...node,
          position: { x: node.x, y: node.y }
        })) ?? nodes,
      edges:
        layoutedGraph.edges?.map((edge: UboReportEdge | ElkExtendedEdge) => {
          if ((edge as UboReportEdge).isDirectOwnership) {
            return edge;
          }

          return {
            ...edge,
            style: { stroke: grey.dark, strokeDasharray: "5,5" }
          };
        }) ?? edges
    }))
    .catch(console.error);
};

import { styled } from "@linaria/react";

import { grey, blue } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import { zIndex } from "styles/zIndex";

const ReportControls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  overflow: none;
  position: relative;
`;

const ReportSectionMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  max-width: 1080px;
`;

const Menu = styled.div`
  background-color: white;
  padding: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.5s ease-in;
  z-index: ${zIndex.top};
  position: absolute;
  top: 30px;
  right: -20px;
  max-height: ${({ open }) => (open ? "1000px" : 0)};
  border-radius: 0.5rem;
  box-shadow: -4px 4px 16px 0px rgba(0, 0, 0, 0.16);

  img {
    width: 20px;
  }
`;

const MenuList = styled.ul`
  padding: 0.5rem;
  margin: 0;
  background-color: white;
`;

const ListItem = styled.li`
  list-style-type: none;
  cursor: pointer;
  padding: 8px 5px 8px 5px;
  background-color: ${grey.panel};
  border-radius: 3px;
  margin-bottom: 8px;
  width: 253px;
  color: ${grey.dark};
  font-family: ${family.interRegular};
  font-size: ${fontSize.sm};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-flow: row wrap;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${blue.hover};
    color: white;
  }
`;

const Title = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-start;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
`;

const ReportSectionButton = styled.button`
  border: 0;
  background-color: transparent;
  width: 28px;
  height: 28px;
  padding: 0;
  path {
    fill: ${grey.dark};
  }

  svg {
    border-radius: 3px;
  }

  &:hover {
    path {
      fill: ${blue.hover};
    }
  }
`;

const S = {
  ReportControls,
  ReportSectionMenu,
  Menu,
  Title,
  ImageWrapper,
  ReportSectionButton,
  MenuList,
  ListItem
};

export default S;

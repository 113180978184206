import React, { FC, useState } from "react";
import ReactTooltip from "components/atoms/ReactTooltip";
import NewReportModal from "components/organisms/NewReportModal";
import { ReactComponent as RerunReportIcon } from "img/icons/menu/reportPage/icon_rerun_report.svg";
import { NavItem } from "reactstrap";
import { SubjectType, ContextType } from "api/enquiry/types";

import S from "./styles";

interface Props {
  label: string;
  subjectType: SubjectType;
  contexts: { type: ContextType; value: string }[];
}

const RerunReportButton: FC<Props> = ({ label, subjectType, contexts }) => {
  const [isNewReportModalOpen, setIsNewReportModalOpen] = useState(false);

  const toggleIsNewReportModalOpen = () =>
    setIsNewReportModalOpen(prev => !prev);
  return (
    <>
      <NavItem onClick={toggleIsNewReportModalOpen}>
        <S.RerunButtonContainer>
          <ReactTooltip
            tooltip="Rerun report"
            open={undefined}
            style={undefined}
            className={undefined}
          >
            <S.RerunButton aria-label="Share report" tabIndex={0}>
              <RerunReportIcon />
            </S.RerunButton>
          </ReactTooltip>
        </S.RerunButtonContainer>
      </NavItem>
      <NewReportModal
        isOpen={isNewReportModalOpen}
        label={label}
        subjectType={subjectType}
        contexts={contexts}
        onClose={toggleIsNewReportModalOpen}
      />
    </>
  );
};

export default RerunReportButton;
